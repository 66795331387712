import { initializeSegment, segmentTrack } from './segment';

export const ROUTE_SELECTED_EVENT = 'route-selected';
export const STOP_SELECTED_EVENT = 'stop-selected';
export const VEHICLE_SELECTED_EVENT = 'vehicle-selected';

let $store = undefined;

const localConfig = {
  // Adds a green tag prefix to logged statements
  log: (...args) => {
    console.debug(
      '%c [Tracking] ',
      'background-color: rgb(71, 184, 129); color: #fff; border-radius: 2px;',
      ...args,
    );
  },
};

const gaTrack = (eventName, args = {}) => {
  if (
    window.isGoogleAnalyticsEnabled &&
    window.gtag != null &&
    typeof window.gtag === 'function'
  ) {
    window.gtag('event', eventName, args.properties);
  }
};

export const initialize = (vcStore) => {
  $store = vcStore;
  initializeSegment();
};

export const track = (eventName, properties) => {
  if (!window.isTrackingEnabled || $store == null) {
    return;
  }

  // augment all tracking with the current agencyKey
  const augmentedProperties = {
    ...(properties ?? {}),
    agencyKey: $store.getters.agencyKey,
  };

  segmentTrack(eventName, { properties: augmentedProperties });
  gaTrack(eventName, { properties: augmentedProperties });
  if (!window.isSegmentEnabled && !window.isGoogleAnalyticsEnabled) {
    localConfig.log(`Tracking event "${eventName}"`, augmentedProperties);
  }
};
