<template>
  <div class="agency-index-container">
    <h1 class="agencies-header">Supported Agencies</h1>
    <ul class="agency-list">
      <li v-for="item in agencies" :key="item.name" class="agency">
        <a :href="item.agencyId" class="containing-anchor">
          <div
            class="logo-round"
            :style="{
              backgroundImage: 'url(' + getLogoSrc(item.agencyId) + ')',
            }"
          />
          <span class="display-name" v-text="item.name"></span>
        </a>
      </li>
    </ul>
    <LegalLinks />
  </div>
</template>

<script>
import LegalLinks from './LegalLinks.vue';

export default {
  name: 'agency-index',
  components: { LegalLinks },
  computed: {
    agencies() {
      return window.agencies;
    },
  },
  methods: {
    getLogoSrc(agencyKey) {
      if (typeof agencyKey === 'string' && agencyKey.length > 0) {
        let iconUrl;
        try {
          iconUrl = require(`../images/agency-icons/${agencyKey}.png`);
        } catch (e) {
          iconUrl = require(`../images/agency-icons/default.png`);
        }
        if (iconUrl) {
          return iconUrl;
        }
      }
      return null;
    },
  },
};
</script>

<style lang="stylus" scoped>

.agency-index-container {
    max-width 960px
    margin 0 auto
    padding 30px 0 90px

    h1 {
        color #434F5B
        font-size 30px
        font-size 3rem
        font-weight 300
        margin-left 10px
    }

    @media screen and (max-width: 500px) {
        h1 {
            width 300px
            text-overflow ellipsis
        }
    }

    .agency-list {
        margin 0 12px
        padding 0
        background-color #fff
        box-shadow 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2)
        font-size 18px
        font-size 1.8rem

        .agency {
            list-style-type none
            height 80px
            line-height 80px
            border-bottom 1px solid #eee
            color #1cadd4
            font-weight 300
            font-size 18px
            white-space nowrap
            overflow hidden
            padding-right 15px
            text-overflow ellipsis
            overlow hidden
            cursor pointer
            position relative

            &:hover {
                background-color #f7f7f7
            }

            .containing-anchor {
                display block
                width 100%
                height 100%
                text-decoration none
                color inherit
            }
            .display-name {
                color rgba(0,0,0,.54)
            }

            //TODO - add ellipsis for overflow avove 300 px

            @media screen and (max-width: 500px) {
                .display-name {
                    width 300px
                    text-overflow ellipsis
                }
            }

            .logo-round {
                border-radius 50%
                float left
                margin 15px
                width 50px
                height 50px
                border 1px solid #ddd
                background-color #fff
                background-size contain
                background-repeat no-repeat
                background-position center
            }
        }
    }
}
</style>
