<template>
  <div id="route-map" class="right-hand-side">
    <div id="transit-map"></div>
  </div>
</template>

<script>
import axios from 'axios';
import { gsap } from 'gsap';
import L from 'leaflet';
import _ from 'lodash';
import moment from 'moment';

import {
  STOP_SELECTED_EVENT,
  track,
  VEHICLE_SELECTED_EVENT,
} from '../tracking';

//<private>
const TILES_API_KEY = 'TA7veYrAmui67YHOLEli';
const SWIFTLY_ORANGE_HEX = '#FF9E16';
const SWIFTLY_BLUE_HEX = '#00A1DF';

const POPUP_PADDING_TOP_LEFT = [64, 64]; // left, top
const POPUP_PADDING_BOTTOM_RIGHT = [64, 64]; // right, bottom

let _leafletMap = null;

let _stopPopup = null;
let _vehiclePopup = null;

let _refreshVehiclePopupInterval = null;
let _refreshStopPopupInterval = null;

let _fetchNextStopPredictionsInterval = null;

let _fetchVehiclesTimeout = null;

let _inboundRouteLines = [];
let _outboundRouteLines = [];

let _renderedStops = [];
let _renderedVehicles = {};

let _justClickedStop = false;
let _justClickedVehicle = false;

let _nextStopArrivalDisplay = 'Loading...';

const _closePopupSafely = (popup) => {
  if (!_.isObjectLike(popup)) {
    return;
  }
  if (typeof popup.closePopup !== 'function') {
    return;
  }
  if (typeof popup.isOpen === 'function') {
    if (!popup.isOpen()) {
      return;
    }
  }
  popup.closePopup();
};

const _openPopupSafely = (popup) => {
  if (!_.isObjectLike(popup)) {
    return;
  }
  if (typeof popup.openPopup !== 'function') {
    return;
  }
  if (typeof popup.isOpen === 'function') {
    if (popup.isOpen()) {
      return;
    }
  }
  popup.openPopup();
};

const _getStopPopupHtmlString = (
  popupData,
  className,
  arrivalTimesArray,
  lastUpdatedText,
  isLoading,
) => {
  if (!_.isObjectLike(popupData) || !className) {
    return;
  }

  let htmlString = `<div class=${className}>`;

  _.forIn(popupData, (value, key) => {
    if (!value) {
      return;
    }
    htmlString += `<p>${value}</p>`;
  });

  htmlString += `<div class="arrival-times-container">`;
  if (_.isArray(arrivalTimesArray) && arrivalTimesArray.length && !isLoading) {
    let arrivalTimesHtml = '';
    let hasScheduledPrediction = false;
    let hasPrediction = false;
    _.forEach(arrivalTimesArray, (item) => {
      if (!_.isObjectLike(item)) {
        return;
      }
      const timesString = _.get(item, 'timesString');
      if (typeof timesString !== 'string' || !timesString) {
        return;
      }
      const timesStringParts = timesString.split('Arriving in ');
      let arrivalTimes = timesStringParts[1]
        ? timesStringParts[1]
        : timesStringParts[0];
      arrivalTimesHtml +=
        `<div class= "single-arrival-time">` +
        `<p class="headsign-arrival">${item.headsign} arrives in</p>` +
        `<h2 class="arrival-times-cta">${arrivalTimes}</h2>` +
        `</div>`;
      if (!hasScheduledPrediction && item.hasScheduleBased) {
        hasScheduledPrediction = true;
      }
      if (!hasPrediction && item.hasPrediction) {
        hasPrediction = true;
      }
    });
    if (lastUpdatedText) {
      const disclaimerText = hasScheduledPrediction
        ? '* Schedule-based'
        : 'All predictions are real-time<i class="material-icons">rss_feed</i>';
      arrivalTimesHtml += `<p class="last-updated-text">${lastUpdatedText}</p>`;
      if (hasPrediction) {
        arrivalTimesHtml += `<p class="last-updated-text">${disclaimerText}</p>`;
      }
    }
    htmlString += arrivalTimesHtml + '</div>';
  } else if (isLoading) {
    htmlString +=
      `<p class="headsign-arrival">Loading arrival data.</p>` +
      `<h2 class="arrival-times-cta">Please wait.</h2>` +
      `</div>`;
  } else {
    htmlString +=
      `<p class="headsign-arrival">Arrival data unavailable.</p>` +
      `<h2 class="arrival-times-cta">Try again later.</h2>` +
      `</div>`;
  }
  htmlString += '</div>';
  return htmlString;
};

const _getVehiclePopupHtmlString = (popupData, className = 'vehicle-popup') => {
  if (!_.isObjectLike(popupData)) {
    return '';
  }

  let htmlString = `<div class=${className}>`;
  _.forIn(popupData, (value, key) => {
    if (value) {
      htmlString += `<p><strong>${key}:</strong> ${value}</p>`;
    }
  });

  htmlString += '</div>';
  return htmlString;
};
//</private>

const LeafletMap = {};

LeafletMap.name = 'leaflet-map';

//<computeds>
LeafletMap.computed = {};
LeafletMap.computed.lastUpdatedText = (vc) => vc.$store.getters.lastUpdatedText;

LeafletMap.computed.currentVehicleMarker = (vc) => {
  const vehicleId = vc.$store.getters.currentVehicleId;
  if (_.isEmpty(vehicleId)) {
    return null;
  }
  if (!_.isObjectLike(_renderedVehicles[vehicleId])) {
    return null;
  }
  return _renderedVehicles[vehicleId];
};

LeafletMap.computed.inboundRouteColor = (vc) =>
  vc.$store.getters.currentRouteColor || SWIFTLY_BLUE_HEX;
LeafletMap.computed.outboundRouteColor = (vc) =>
  vc.$store.getters.currentRouteColor || SWIFTLY_ORANGE_HEX;
//</computeds>

//<methods>
LeafletMap.methods = {};

LeafletMap.methods.prepareRouteShapes = function () {
  const vc = this;

  const routeShapes = vc.$store.getters.currentRouteShapes;
  if (_.isEmpty(routeShapes)) {
    return;
  }

  const inboundPolylineConfig = {
    color: vc.inboundRouteColor,
    className: 'animated fadeIn',
    smoothFactor: 0,
    weight: 6,
    lineCap: 'circle',
  };
  const outboundPolylineConfig = {
    color: vc.outboundRouteColor,
    className: 'animated fadeIn',
    weight: 6,
    smoothFactor: 0,
    lineCap: 'circle',
  };

  const newInboundPolylines = [];
  const newOutboundPolylines = [];

  _.forEach(routeShapes, (shape) => {
    if (!_.isObjectLike(shape)) {
      return;
    }
    if (!_.isObjectLike(shape.locs)) {
      return;
    }

    // Review with product: do we render shapes marked as "minor"
    // We do not in the dashbaord. Previously this was written to address
    // a bug which I believe was due to not rendering each shape separately
    // if ( shape.minor ) { return }

    // It is important to create separate polylines per route shape
    // Otherwise, the last point of one route shape can connect to the
    // beginning of another shape
    if (shape.directionId === '1') {
      newInboundPolylines.push(L.polyline(shape.locs, inboundPolylineConfig));
    }
    if (shape.directionId === '0') {
      newOutboundPolylines.push(L.polyline(shape.locs, outboundPolylineConfig));
    }
  });

  // Before we overwrite the stored polylines, remove them from the map
  vc.removeInboundPolylines();
  vc.removeOutboundPolylines();

  // Now, overwrite the stored polylines
  _inboundRouteLines = newInboundPolylines;
  _outboundRouteLines = newOutboundPolylines;

  // If we have a selected direction, draw it
  const directionId = vc.$store.getters.currentDirectionId;
  if (directionId === '1') {
    vc.drawInboundPolylines();
  }
  if (directionId === '0') {
    vc.drawOutboundPolylines();
  }
};

LeafletMap.methods.drawStops = function (newStopData) {
  const vc = this;

  if (!_.isArray(newStopData)) {
    return;
  }

  vc.removeStops();

  const currentDirectionId = vc.$store.getters.currentDirectionId;

  let stopColor = vc.inboundRouteColor;
  if (currentDirectionId === '1') {
    stopColor = vc.inboundRouteColor;
  }
  if (currentDirectionId === '0') {
    stopColor = vc.outboundRouteColor;
  }

  _.forEach(newStopData, (stop) => {
    const stopCircle = L.circleMarker([stop.lat, stop.lon], {
      stopId: stop.code,
      stopName: stop.name,
      color: stopColor,
      radius: 7,
      fillColor: '#FFFFFF',
      fillOpacity: 1,
      // Store a reference to the click handler in options
      // This allows us to call .off() with the same function .on()
      // was called with, preventing a memory leak
      clickHandler: () => {
        _justClickedStop = true;
        setTimeout(() => {
          _justClickedStop = false;
        }, 250);
        track(STOP_SELECTED_EVENT, { source: 'map' });
        vc.$store.commit('currentStopCode', stop.code);
      },
    });
    stopCircle.on('click', stopCircle.options.clickHandler);
    stopCircle.addTo(_leafletMap);
    _renderedStops.push(stopCircle);
  });
};

LeafletMap.methods.removeStops = function () {
  const vc = this;
  if (!_.isArray(_renderedStops)) {
    return;
  }
  if (_.isEmpty(_renderedStops)) {
    return;
  }
  _.forEach(_renderedStops, (stop) => {
    if (!_.isObjectLike(stop)) {
      return;
    }
    if (typeof stop.off === 'function') {
      const clickHandler = _.get(stop, ['options', 'clickHandler']);
      if (typeof clickHandler === 'function') {
        stop.off('click', clickHandler);
      }
    }
    if (typeof stop.remove === 'function') {
      stop.remove();
    }
  });
  _renderedStops = [];
};

LeafletMap.methods.fetchNextStopPredictions = async function () {
  const vc = this;

  const agencyKey = vc.$store.getters.agencyKey;
  const vehicle = vc.$store.getters.currentVehicle;
  if (_.isEmpty(vehicle)) {
    return;
  }
  const nextStopId = _.get(vehicle, 'nextStopId');
  if (!nextStopId) {
    return;
  }
  const shortName = vc.$store.getters.currentRouteShortName;
  if (!shortName) {
    return;
  }

  const url =
    `${process.env.API_GATEWAY_URL}/real-time/${agencyKey}/predictions` +
    `?route=${shortName}&stop=${nextStopId}`;
  const headers = { Authorization: process.env.SWIFTLY_AUTH_KEY };
  const fetchConfig = {
    method: 'GET',
    url,
    headers,
    timeout: 0,
  };
  let resp = undefined;
  try {
    resp = await axios(fetchConfig);
  } catch (error) {
    console.log(`fetchNextStopPredictions error: ${error.message}`);
    return;
  }
  vc.setNextStopArrivalDisplay(resp.data);
};

LeafletMap.methods.setNextStopArrivalDisplay = function (results) {
  const vc = this;
  const vehicle = vc.$store.getters.currentVehicle;
  if (_.isEmpty(vehicle)) {
    return;
  }
  const DEFAULT_ERROR_TEXT = 'Times unavailable. Reloading...';
  const LESS_THAN_1_MINUTE_TEXT = `< 1`;
  const SINGULAR_TIME_LABEL = 'minute';
  const PLURAL_TIME_LABEL = 'minutes';
  const destinations = _.get(results, [
    'data',
    'predictionsData',
    '0',
    'destinations',
  ]);
  if (!_.isArray(destinations)) {
    _nextStopArrivalDisplay = DEFAULT_ERROR_TEXT;
  }
  // First try to match the destination based upon headsign
  let destination = _.find(
    destinations,
    (destination) => destination.headsign === vehicle.headsign,
  );
  // If that does not work, just take the first destination
  if (!_.isObjectLike(destination)) {
    destination = _.head(destinations);
  }
  if (!_.isObjectLike(destination)) {
    return (_nextStopArrivalDisplay = DEFAULT_ERROR_TEXT);
  }
  const nextPrediction = _.get(destination, ['predictions', '0']);
  if (!_.isObjectLike(nextPrediction)) {
    return (_nextStopArrivalDisplay = DEFAULT_ERROR_TEXT);
  }
  if (!_.isFinite(nextPrediction.time)) {
    return (_nextStopArrivalDisplay = DEFAULT_ERROR_TEXT);
  }
  if (nextPrediction.tripId !== vehicle.tripId) {
    return (_nextStopArrivalDisplay = 'Vehicle may have passed stop');
  }
  const secondsInFuture = Math.round(nextPrediction.time - Date.now() / 1000);
  let minutesAway = Math.floor(secondsInFuture / 60);
  let minutesLabel = PLURAL_TIME_LABEL;
  if (minutesAway <= 1) {
    minutesLabel = SINGULAR_TIME_LABEL;
  }
  if (minutesAway < 1) {
    minutesAway = LESS_THAN_1_MINUTE_TEXT;
  }
  _nextStopArrivalDisplay = `In ${minutesAway} ${minutesLabel}`;
};

LeafletMap.methods.refreshVehicles = function (vehicles) {
  const vc = this;

  if (!_.isArray(vehicles)) {
    return;
  }

  // Remove rendered vehicles no longer present in returned data
  const oldKeys = _.keys(_renderedVehicles);
  const newKeys = _.map(vehicles, (vehicle) => vehicle.id);
  const differentKeys = _.difference(oldKeys, newKeys);
  _.forEach(differentKeys, (id) => {
    if (!_.isObjectLike(_renderedVehicles[id])) {
      return;
    }
    const isOldVehicleId = oldKeys.indexOf(id) !== -1;
    if (isOldVehicleId) {
      vc.removeVehicle(_renderedVehicles[id]);
    }
  });

  // Either update or draw remaining vehicles
  _.forEach(vehicles, (vehicle) => {
    const renderedVehicle = _renderedVehicles[vehicle.id];

    // No need to update a vehicle if it is not in the selected direction
    if (vehicle.directionId !== vc.$store.getters.currentDirectionId) {
      if (_.isObjectLike(renderedVehicle)) {
        vc.removeVehicle(renderedVehicle);
      }
      return;
    }

    if (_.isObjectLike(renderedVehicle)) {
      vc.updateVehicle(vehicle, renderedVehicle);
    } else {
      vc.drawVehicle(vehicle);
    }
  });
};

LeafletMap.methods.drawVehicle = function (vehicle) {
  const vc = this;

  const routeDetails = vc.$store.getters.currentRouteDetails;
  if (!_.isObjectLike(routeDetails)) {
    return;
  }

  let heading = _.get(vehicle, ['loc', 'heading'], 0);
  heading = _.isFinite(heading) ? Math.floor(heading) : 'missing';

  const vehicleMarker = L.marker([vehicle.loc.lat, vehicle.loc.lon], {
    vehicleId: vehicle.id,
    icon: L.divIcon({
      className: `vehicle-heading-icon heading-${heading}`,
      iconSize: [38, 38],
      iconAnchor: [19, 19],
    }),
    // Store a reference to the click handler in options
    // This allows us to call .off() with the same function .on()
    // was called with, preventing a memory leak
    clickHandler: () => {
      _justClickedVehicle = true;
      setTimeout(() => {
        _justClickedVehicle = false;
      }, 250);
      track(VEHICLE_SELECTED_EVENT);
      vc.$store.commit('currentVehicleId', vehicle.id);
    },
  });
  vehicleMarker.on('click', vehicleMarker.options.clickHandler);
  vehicleMarker.addTo(_leafletMap);
  _renderedVehicles[vehicle.id] = vehicleMarker;
};

LeafletMap.methods.vehiclePopupHTML = function () {
  const vc = this;

  const vehicle = vc.$store.getters.currentVehicle;
  const routeDetails = vc.$store.getters.currentRouteDetails;
  const directionDisplayName = vc.$store.getters.currentDirectionDisplayName;

  return _getVehiclePopupHtmlString({
    'Route': _.get(routeDetails, 'name', '...'),
    'Vehicle ID': _.get(vehicle, 'id', '...'),
    'Direction': directionDisplayName || '...',
    'Last GPS Ping': vc.lastGpsPingDisplay() || '...',
    'Next Stop': _.get(vehicle, 'nextStopName', '...'),
    'Arrival': _nextStopArrivalDisplay,
  });
};

LeafletMap.methods.lastGpsPingDisplay = function () {
  const vc = this;
  const vehicle = vc.$store.getters.currentVehicle;
  const lastPingTime = _.get(vehicle, ['loc', 'time']);
  if (!_.isFinite(lastPingTime)) {
    return '...';
  }
  const secondsAgo = Math.floor(moment.now() / 1000 - lastPingTime);
  if (secondsAgo < 60) {
    return `${secondsAgo} seconds ago`;
  }
  return moment.duration(secondsAgo, 'seconds').humanize(false) + ' ago';
};

LeafletMap.methods.stopPopupHTML = function () {
  const vc = this;
  const currentStop = vc.$store.getters.currentStop;
  if (!_.isObjectLike(currentStop)) {
    return '';
  }
  return _getStopPopupHtmlString(
    {
      'Stop Name': _.get(currentStop, 'name', '...'),
      'Stop Code': `Stop Code ${_.get(currentStop, 'code', '...')}`,
    },
    'stop-popup',
    vc.$store.getters.arrivalTimesArray,
    vc.lastUpdatedText,
    vc.$store.getters.isLoadingPredictions,
  );
};

LeafletMap.methods.openStopPopup = function (stop) {
  const vc = this;
  _closePopupSafely(_stopPopup); // heyo
  _stopPopup = L.popup({
    autoPan: true,
    autoPanPaddingTopLeft: POPUP_PADDING_TOP_LEFT,
    autoPanPaddingBottomRight: POPUP_PADDING_BOTTOM_RIGHT,
    className: 'stop-popup-wrap',
    closeButton: false,
    minWidth: 165,
  });
  _stopPopup.setLatLng(L.latLng(stop.lat, stop.lon));
  _stopPopup.setContent(vc.stopPopupHTML());
  _leafletMap.openPopup(_stopPopup);
};

LeafletMap.methods.closeStopPopup = function () {
  const vc = this;
  _leafletMap.closePopup(_stopPopup);
  _stopPopup = null;
};

LeafletMap.methods.openVehiclePopup = function (vehicle) {
  const vc = this;
  _closePopupSafely(_vehiclePopup);
  _vehiclePopup = L.popup({
    autoPan: true,
    autoPanPaddingTopLeft: POPUP_PADDING_TOP_LEFT,
    autoPanPaddingBottomRight: POPUP_PADDING_BOTTOM_RIGHT,
    className: `vehicle-popup vehicle-id-${vehicle.id}`,
    closeButton: false,
    vehicleId: vehicle.id,
  });
  _vehiclePopup.setLatLng(L.latLng(vehicle.loc.lat, vehicle.loc.lon));
  _vehiclePopup.setContent(vc.vehiclePopupHTML());
  _leafletMap.openPopup(_vehiclePopup);
  _nextStopArrivalDisplay = 'Loading...';
  vc.fetchNextStopPredictions();
  clearInterval(_fetchNextStopPredictionsInterval);
  _fetchNextStopPredictionsInterval = setInterval(
    vc.fetchNextStopPredictions,
    5000,
  );
};

LeafletMap.methods.closeVehiclePopup = function () {
  const vc = this;
  clearInterval(_fetchNextStopPredictionsInterval);
  _leafletMap.closePopup(_vehiclePopup);
  _vehiclePopup = null;
  _nextStopArrivalDisplay = 'Loading...';
};

LeafletMap.methods.updateVehicle = function (vehicle, vehicleMarker) {
  const vc = this;

  if (!_.isObjectLike(vehicle)) {
    return;
  }
  if (!_.isObjectLike(vehicleMarker)) {
    return;
  }

  let heading = _.get(vehicle, ['loc', 'heading'], 'missing');
  if (_.isFinite(heading)) {
    heading = Math.floor(heading);
  }
  vehicleMarker.setIcon(
    L.divIcon({
      className: `vehicle-heading-icon heading-${heading}`,
      iconSize: [38, 38],
      iconAnchor: [19, 19],
    }),
  );

  const oldLocation = vehicleMarker.getLatLng();
  if (!_.isObjectLike(oldLocation)) {
    return;
  }
  if (!_.isFinite(oldLocation.lat)) {
    return;
  }
  if (!_.isFinite(oldLocation.lng)) {
    return;
  }

  const newLocation = { lat: vehicle.loc.lat, lon: vehicle.loc.lon };

  gsap.to(
    {
      lat: oldLocation.lat,
      lng: oldLocation.lng,
    },
    2, // animationDurationInSeconds
    {
      lat: newLocation.lat,
      lng: newLocation.lon,
      onUpdate: function () {
        const latLng = this.targets()[0];
        vehicleMarker.setLatLng(latLng);
        if (vehicle.id === vc.$store.getters.currentVehicleId) {
          if (_.isObjectLike(_vehiclePopup)) {
            _vehiclePopup.setLatLng(latLng);
          }
        }
      },
      onComplete: () => {
        const latLng = L.latLng(newLocation.lat, newLocation.lon);
        vehicleMarker.setLatLng(latLng);
        if (vehicle.id === vc.$store.getters.currentVehicleId) {
          if (_.isObjectLike(_vehiclePopup)) {
            _vehiclePopup.setLatLng(latLng);
          }
        }
      },
    },
  );
};

LeafletMap.methods.pollVehicleInfo = async function () {
  const vc = this;

  const agencyKey = vc.$store.getters.agencyKey;
  const currentRouteShortName = vc.$store.getters.currentRouteShortName;

  let validState = true;
  if (typeof agencyKey !== 'string' || !agencyKey) {
    validState = false;
  }
  if (typeof currentRouteShortName !== 'string' || !currentRouteShortName) {
    validState = false;
  }
  if (!validState) {
    console.log(`LeafletMap.methods.pollVehicleInfo invalid state`);
    clearTimeout(_fetchVehiclesTimeout);
    _fetchVehiclesTimeout = setTimeout(vc.pollVehicleInfo, 10000);
    return;
  }

  const url =
    `${process.env.API_GATEWAY_URL}/real-time/${agencyKey}/vehicles` +
    `?route=${currentRouteShortName}&requestTime=${moment.now()}`;
  const headers = { Authorization: process.env.SWIFTLY_AUTH_KEY };
  const fetchConfig = {
    method: 'GET',
    url,
    headers,
    timeout: 0,
  };
  let resp = undefined;
  try {
    resp = await axios(fetchConfig);
  } catch (error) {
    console.log`LeafletMap.methods.pollVehicleInfo error: ${error.message}`;
    clearTimeout(_fetchVehiclesTimeout);
    _fetchVehiclesTimeout = setTimeout(vc.pollVehicleInfo, 10000);
    return;
  }
  const vehicles = resp.data.data.vehicles;
  const cleanedVehicles = _.filter(vehicles, (vehicle) => {
    if (!_.isObjectLike(vehicle)) {
      return false;
    }
    if (typeof vehicle.id !== 'string') {
      return false;
    }
    if (!_.isObjectLike(vehicle.loc)) {
      return false;
    }
    if (!_.isFinite(vehicle.loc.lat)) {
      return false;
    }
    if (!_.isFinite(vehicle.loc.lon)) {
      return false;
    }
    if (vehicle.layover) {
      return false;
    }
    return true;
  });
  vc.$store.commit('vehicles', cleanedVehicles);
  vc.refreshVehicles(cleanedVehicles);
  clearTimeout(_fetchVehiclesTimeout);
  _fetchVehiclesTimeout = setTimeout(vc.pollVehicleInfo, 5000);
};

LeafletMap.methods.refreshBounds = function () {
  const vc = this;
  if (!_.isObjectLike(_leafletMap)) {
    return;
  }
  if (typeof _leafletMap.fitBounds !== 'function') {
    return;
  }
  const bounds = vc.$store.getters.currentBounds;
  if (_.isEmpty(bounds)) {
    return;
  }
  _leafletMap.fitBounds(bounds);
};

LeafletMap.methods.drawInboundPolylines = function () {
  const vc = this;
  if (!_.isArray(_inboundRouteLines)) {
    return;
  }
  if (_.isEmpty(_inboundRouteLines)) {
    return;
  }
  _.forEach(_inboundRouteLines, (line) => {
    if (!_.isObjectLike(line)) {
      return;
    }
    if (typeof line.addTo !== 'function') {
      return;
    }
    line.addTo(_leafletMap);
    // bringToBack() can trigger an error if not already added to a map
    if (typeof line.bringToBack !== 'function') {
      return;
    }
    line.bringToBack();
  });
};

LeafletMap.methods.drawOutboundPolylines = function () {
  const vc = this;
  if (!_.isArray(_outboundRouteLines)) {
    return;
  }
  if (_.isEmpty(_outboundRouteLines)) {
    return;
  }
  _.forEach(_outboundRouteLines, (line) => {
    if (!_.isObjectLike(line)) {
      return;
    }
    if (typeof line.addTo !== 'function') {
      return;
    }
    line.addTo(_leafletMap);
    // bringToBack() can trigger an error if not already added to a map
    if (typeof line.bringToBack !== 'function') {
      return;
    }
    line.bringToBack();
  });
};

LeafletMap.methods.removeInboundPolylines = function () {
  const vc = this;
  if (_.isEmpty(_inboundRouteLines)) {
    return;
  }
  _.forEach(_inboundRouteLines, (line) => {
    if (!_.isObjectLike(line)) {
      return;
    }
    if (typeof line.remove === 'function') {
      line.remove();
    }
  });
};

LeafletMap.methods.removeOutboundPolylines = function () {
  const vc = this;
  if (_.isEmpty(_outboundRouteLines)) {
    return;
  }
  _.forEach(_outboundRouteLines, (line) => {
    if (!_.isObjectLike(line)) {
      return;
    }
    if (typeof line.remove === 'function') {
      line.remove();
    }
  });
};

LeafletMap.methods.removeVehicles = function () {
  const vc = this;
  if (!_.isObjectLike(_renderedVehicles)) {
    return;
  }
  if (_.isEmpty(_renderedVehicles)) {
    return;
  }
  _.forEach(_renderedVehicles, (vehicle) => vc.removeVehicle(vehicle));
};

LeafletMap.methods.removeVehicle = function (vehicle) {
  const vc = this;
  if (!_.isObjectLike(vehicle)) {
    return;
  }
  if (typeof vehicle.isPopupOpen === 'function' && vehicle.isPopupOpen()) {
    _closePopupSafely(vehicle);
  }
  if (typeof vehicle.off === 'function') {
    const clickHandler = _.get(vehicle, ['options', 'clickHandler']);
    if (typeof clickHandler === 'function') {
      vehicle.off('click', clickHandler);
    }
  }
  if (typeof vehicle.remove === 'function') {
    vehicle.remove();
  }
  const vehicleId = _.get(vehicle, ['options', 'vehicleId']);
  if (typeof vehicleId !== 'string') {
    return;
  }
  if (!_.isObjectLike(_renderedVehicles[vehicleId])) {
    return;
  }
  delete _renderedVehicles[vehicleId];
};

LeafletMap.methods.clearRouteSelection = function () {
  const vc = this;
  vc.removeVehicles();
  vc.removeStops();
  vc.removeInboundPolylines();
  vc.removeOutboundPolylines();
  _inboundRouteLines = [];
  _outboundRouteLines = [];
};

LeafletMap.methods.onMapClick = function () {
  const vc = this;
  if (!_justClickedStop) {
    vc.$store.commit('currentStopCode', '');
  }
  if (!_justClickedVehicle) {
    vc.$store.commit('currentVehicleId', '');
  }
};
//</methods>

//<watchers>
LeafletMap.watch = {};

LeafletMap.watch['$store.getters.heartbeat'] = function () {
  const vc = this;

  if (_.isObjectLike(_vehiclePopup)) {
    if (typeof _vehiclePopup.setContent === 'function') {
      _vehiclePopup.setContent(vc.vehiclePopupHTML());
    }
  }
  if (_.isObjectLike(_stopPopup)) {
    if (typeof _stopPopup.setContent === 'function') {
      _stopPopup.setContent(vc.stopPopupHTML());
    }
  }
};

LeafletMap.watch['$store.getters.currentStop'] = function (stop) {
  const vc = this;
  if (_.isEmpty(stop)) {
    vc.closeStopPopup();
    return;
  }
  // Only one popup at a time: if a stop is a selected, a vehicle is not
  vc.$store.commit('currentVehicleId', '');
  vc.openStopPopup(stop);
};

LeafletMap.watch['$store.getters.currentVehicle'] = function (vehicle) {
  const vc = this;
  if (_.isEmpty(vehicle)) {
    vc.closeVehiclePopup();
    return;
  }

  if (!_.isObjectLike(_vehiclePopup)) {
    vc.openVehiclePopup(vehicle);
  } else {
    const popupVehicleId = _.get(_vehiclePopup, ['options', 'vehicleId']);
    if (popupVehicleId !== vehicle.id) {
      vc.openVehiclePopup(vehicle);
    }
  }

  // Only one popup at a time: if a stop is a selected, a vehicle is not
  vc.$store.commit('currentStopCode', '');
};

LeafletMap.watch['$store.getters.currentBounds'] = function (agencyBounds) {
  const vc = this;
  vc.refreshBounds();
};

LeafletMap.watch['$store.getters.currentRouteShortName'] = function (
  routeDisplayName,
) {
  const vc = this;
  clearTimeout(_fetchVehiclesTimeout);
  vc.clearRouteSelection();
  if (routeDisplayName) {
    vc.prepareRouteShapes();
    vc.pollVehicleInfo();
  }
  vc.refreshBounds();
};

LeafletMap.watch['$store.getters.currentDirectionId'] = function (
  newDirectionId,
) {
  const vc = this;

  vc.removeOutboundPolylines();
  vc.removeInboundPolylines();

  switch (newDirectionId) {
    case '1':
      vc.removeOutboundPolylines();
      vc.drawInboundPolylines();
      break;
    case '0':
      vc.removeInboundPolylines();
      vc.drawOutboundPolylines();
      break;
  }

  vc.refreshVehicles(vc.$store.getters.vehicles);
};

LeafletMap.watch['$store.getters.currentStopOptions'] = function (newStops) {
  const vc = this;
  vc.drawStops(newStops);
};
//</watchers>

LeafletMap.mounted = function () {
  const vc = this;

  const map = L.map('transit-map');

  L.tileLayer(
    `https://api.maptiler.com/maps/positron/256/{z}/{x}/{y}@2x.png?key=${TILES_API_KEY}`,
    {
      attribution: '&copy;<span>Swiftly, Inc</span>',
      maxZoom: 18,
    },
  ).addTo(map);

  map.on('click', vc.onMapClick);

  _leafletMap = map;
};

LeafletMap.beforeDestroy = function () {
  const vc = this;

  clearInterval(_refreshStopPopupInterval);
  clearInterval(_refreshVehiclePopupInterval);
  clearInterval(_fetchNextStopPredictionsInterval);

  if (_.isObjectLike(_leafletMap)) {
    if (typeof _leafletMap.off === 'function') {
      _leafletMap.off('click', vc.onMapClick);
    }
    if (typeof _leafletMap.remove === 'function') {
      _leafletMap.remove();
    }
    _leafletMap = undefined;
  }
};

export default LeafletMap;
</script>

<style lang="stylus">
// Make us some directional classes
rotateByDegrees( n ) {
   transform rotate((n)deg)
}
for num in (1..360)
   .heading-{num}:before
       rotateByDegrees(num)

.heading-missing {
    display none
}

#route-map {
    height 100%
}

#transit-map.leaflet-container {
    height 100vh

    .stop-popup p, .vehicle-popup p {
        font-family Roboto, sans-serif
        margin 3px 0
    }

    .stop-popup {
        > p:first-child {
            font-weight 700
        }
        > p:last-child {
            font-weight 300
        }
    }

    .stop-popup-wrap {
        .leaflet-popup-content-wrapper {
            background-color #00A1DF
            color #FFFFFF
            border-radius 0
            padding 1px 0 0 0
        }
        .leaflet-popup-close-button {
            color #FFFFFF
            &:hover {
                color #F5C936
            }
        }
        .arrival-times-container {
            background-color #FFFFFF
            color #000000
            min-height 102px
            padding 1rem
            margin 10px -24px -13px -20px
            text-align center

            .single-arrival-time {
               margin-bottom 20px
               padding-bottom 10px
               border-bottom 1px solid #ccc
            }

            .arrival-times-cta {
                margin-top 0px
            }

            p {

                &.headsign-arrival {
                    font-size 16px
                    color #222
                    font-size 0.85rem
                }

                &.last-updated-text {
                    color #57575C
                    padding-top 0px
                }
            }

            h2 {
                margin-top 10px
                margin-bottom 10px
                font-size 1.1rem
                font-weight 700
            }
        }
    }

    .vehicle-popup {
        .leaflet-popup-content-wrapper {
            border-radius 0
        }
    }

    .vehicle-heading-icon {
        background-color #600CAC
        border 2px solid #ffffff
        border-radius 50%
        opacity .75
        outline none

        &.invisible {
            display none
        }
    }

    .vehicle-heading-icon:before {
        content ""
        display block
        width 100%
        height 100%
        background-image url("../images/vehicle_heading_icon_96x96.png")
        background-size 50%
        background-repeat no-repeat
        background-position center center
        opacity 1
    }
}
</style>
