<template>
  <div class="legal-links">
    <a href="https://goswift.ly/privacy-policy" target="_blank"
      >Privacy Policy</a
    >
    <span>|</span>
    <a href="https://goswift.ly/terms-of-use" target="_blank">Terms of Use</a>
    <div v-show="$store.getters.isTrackingEnabled">
      <!-- TermsFeed JavaScript uses this id to attach a click handler -->
      <a href="#" id="open_preferences_center">Cookies Preferences</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'legal-links',
};
</script>

<style lang="stylus" scoped>
.legal-links {
    margin-top 30px
    text-align center
    > span {
        margin 0 6px
    }
}
</style>
