import _ from 'lodash';

export const agencyBounds = (state, payload) => {
  if (_.isEqual(state.agencyKey, payload)) {
    return;
  }
  state['agencyBounds'] = payload;
};

export const agencyKey = (state, payload) => {
  if (_.isEqual(state.agencyKey, payload)) {
    return;
  }
  state['agencyKey'] = payload;
};

export const agencyName = (state, payload) => {
  if (_.isEqual(state.agencyName, payload)) {
    return;
  }
  state['agencyName'] = payload;
};

export const allRouteDetails = (state, payload) => {
  if (_.isEqual(state.allRouteDetails, payload)) {
    return;
  }
  if (!_.isObjectLike(payload)) {
    return;
  }
  state['allRouteDetails'] = payload;
};

export const currentRouteShortName = (state, payload) => {
  if (_.isEqual(state.currentRouteShortName, payload)) {
    return;
  }
  state['currentRouteShortName'] = payload;
};

export const currentDirectionId = (state, payload) => {
  if (_.isEqual(state.currentDirectionId, payload)) {
    return;
  }
  state['currentDirectionId'] = payload;
};

export const currentPredictions = (state, payload) => {
  if (_.isEqual(state.currentPredictions, payload)) {
    return;
  }
  state['currentPredictions'] = payload;
};

export const currentStopCode = (state, payload) => {
  if (_.isEqual(state.currentStopCode, payload)) {
    return;
  }
  state['currentStopCode'] = payload;
};

export const currentVehicleId = (state, payload) => {
  if (_.isEqual(state.currentVehicleId, payload)) {
    return;
  }
  state['currentVehicleId'] = payload;
};

export const heartbeat = (state, payload) => {
  if (_.isEqual(state.heartbeat, payload)) {
    return;
  }
  state['heartbeat'] = payload;
};

export const initialRouteKey = (state, payload) => {
  if (_.isEqual(state.initialRouteKey, payload)) {
    return;
  }
  state['initialRouteKey'] = payload;
};

export const initialStopKey = (state, payload) => {
  if (_.isEqual(state.initialStopKey, payload)) {
    return;
  }
  state['initialStopKey'] = payload;
};

export const isLoadingPredictions = (state, payload) => {
  if (_.isEqual(state.isLoadingPredictions, payload)) {
    return;
  }
  state['isLoadingPredictions'] = payload;
};

export const lastUpdatedText = (state, payload) => {
  if (_.isEqual(state.lastUpdatedText, payload)) {
    return;
  }
  state['lastUpdatedText'] = payload;
};

export const selectedStopCode = (state, payload) => {
  if (_.isEqual(state.selectedStopCode, payload)) {
    return;
  }
  state['selectedStopCode'] = payload;
};

export const vehicles = (state, payload) => {
  if (!_.isArray(payload)) {
    return;
  }
  const stringifiedJson = JSON.stringify(payload);
  if (_.isEqual(state.vehicles, stringifiedJson)) {
    return;
  }
  state.vehicles = stringifiedJson;
};

export const hideLogoSection = (state, payload) => {
  if (_.isEqual(state.hideLogoSection, payload)) {
    return;
  }
  state['hideLogoSection'] = payload;
};
