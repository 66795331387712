<template>
  <div id="agency-predictions-container">
    <div class="left-hand-side">
      <nav-agency-header></nav-agency-header>
      <nav-controls :isLargeScreen="isLargeScreen"></nav-controls>
      <nav-footer></nav-footer>
    </div>
    <leaflet-map v-if="isLargeScreen"></leaflet-map>
  </div>
</template>

<script>
import _ from 'lodash';

import NavAgencyHeader from './NavAgencyHeader.vue';
import NavControls from './NavControls.vue';
import NavFooter from './NavFooter.vue';
import LeafletMap from './LeafletMap.vue';

// <private>
const LARGE_BREAKPOINT_THRESHHOLD_IN_PX = 812;
// </private>

const AgencyPredictionsContainer = {};

AgencyPredictionsContainer.name = 'agency-predictions-container';

AgencyPredictionsContainer.components = {};
AgencyPredictionsContainer.components['nav-controls'] = NavControls;
AgencyPredictionsContainer.components['nav-agency-header'] = NavAgencyHeader;
AgencyPredictionsContainer.components['nav-footer'] = NavFooter;
AgencyPredictionsContainer.components['leaflet-map'] = LeafletMap;

AgencyPredictionsContainer.data = () => ({
  isLargeScreen: false,
});

// <methods>
AgencyPredictionsContainer.methods = {};

AgencyPredictionsContainer.methods.handleResize = _.debounce(function () {
  const vc = this;
  if (window && window.innerWidth > LARGE_BREAKPOINT_THRESHHOLD_IN_PX) {
    vc.isLargeScreen = true;
  } else {
    vc.isLargeScreen = false;
  }
}, 350);
// </methods>

// <lifecycle hooks>
AgencyPredictionsContainer.created = function () {
  const vc = this;

  if (window && window.innerWidth > LARGE_BREAKPOINT_THRESHHOLD_IN_PX) {
    vc.isLargeScreen = true;
  }
  window.addEventListener('resize', vc.handleResize);
};

AgencyPredictionsContainer.beforeDestroy = function () {
  const vc = this;
  window.removeEventListener('resize', vc.handleResize);
};
// </lifecycle hooks>

export default AgencyPredictionsContainer;
</script>

<style lang="stylus">

#agency-predictions-container {
    padding 0
    margin 0
    overflow hidden
}

.right-hand-side {
    display none
}
.left-hand-side {
    overflow-y scroll
    overflow-x hidden
    height 100vh
    -webkit-overflow-scrolling: touch;
}
.material-icons {
    font-size 12px
}

.last-updated-text {
    display flex
    justify-content center
    align-items center
    line-height 1.2
    font-size 12px
}
// TODO: we could perhaps make this less magic numbery
@media screen and (min-width: 813px) {
    .left-hand-side {
        background-color #FFF
        float left
        width 320px
        z-index 1999
        border-right 4px solid #CCC
        -webkit-overflow-scrolling: auto;
    }
    .right-hand-side {
        display block
        position absolute
        top 0
        left 320px
        width calc(100vw - 320px)
        height 100%
    }
}
</style>
