<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import axios from 'axios';
import { initialize as initializeTracking } from '../tracking';
import AgencyIndex from './AgencyIndex.vue';
import AgencyPredictionsContainer from './AgencyPredictionsContainer.vue';

const _initializeRoute = (vc) => {
  if (!_.isObjectLike(vc.$store)) {
    return;
  }
  if (typeof vc.$store.commit !== 'function') {
    return;
  }
  if (!_.isObjectLike(vc.$route)) {
    return;
  }
  if (!_.isObjectLike(vc.$route.params)) {
    return;
  }

  if (typeof vc.$route.params.agencyKey === 'string') {
    vc.$store.commit('agencyKey', vc.$route.params.agencyKey);
  }

  if (typeof vc.$route.params.routeKey === 'string') {
    vc.$store.commit('initialRouteKey', vc.$route.params.routeKey);
  }

  if (typeof vc.$route.params.stopKey === 'string') {
    vc.$store.commit('initialStopKey', vc.$route.params.stopKey);
  }

  // Hide the logo if the query param is simply present, regardless of value
  if (Object.keys(vc.$route.query).includes('hideLogoSection')) {
    vc.$store.commit('hideLogoSection', true);
  }
};

const _initializeAgencyInfo = async function (vc) {
  const agencyKey = vc.$store.getters.agencyKey;
  if (!agencyKey) {
    return;
  }

  const url = `${process.env.API_GATEWAY_URL}/info/${agencyKey}`;
  const headers = { Authorization: process.env.SWIFTLY_AUTH_KEY };
  const fetchConfig = {
    method: 'GET',
    url,
    headers,
    timeout: 0,
  };
  let resp = undefined;
  try {
    resp = await axios(fetchConfig);
  } catch (error) {
    console.log(`Error! fetchAgencyBounds: ${error.message}`);
    return;
  }
  const extent = resp.data.data.extent;
  const { minLat, minLon, maxLat, maxLon } = extent;
  vc.$store.commit('agencyBounds', [
    [minLat, minLon],
    [maxLat, maxLon],
  ]);

  const agencyName = resp.data.data.name;
  if (agencyName) {
    vc.$store.commit('agencyName', agencyName);
  }
};

const _catchNavigationDuplicated = (e) => {
  if (e.name !== 'NavigationDuplicated') {
    throw e;
  }
};

const _updateRoute = (vc) => {
  const agencyKey = vc.$store.getters.agencyKey;
  const routeKey = vc.$store.getters.currentRouteShortName;
  const stopKey = vc.$store.getters.currentStopCode;

  if (agencyKey && routeKey && stopKey) {
    vc.$router
      .push({
        name: 'agency/route/stop',
        params: { agencyKey, routeKey, stopKey },
      })
      .catch(_catchNavigationDuplicated);
    return;
  }

  if (agencyKey && routeKey) {
    vc.$router
      .push({
        name: 'agency/route',
        params: { agencyKey, routeKey },
      })
      .catch(_catchNavigationDuplicated);
    return;
  }

  if (agencyKey) {
    vc.$router
      .push({
        name: 'agency',
        params: { agencyKey },
      })
      .catch(_catchNavigationDuplicated);
    return;
  }

  vc.$router.push({ name: 'default' });
};

// Definitely cleaner approaches out there (e.g., actions), but this is tested and functional
const _handleInitialRouteKeyLoad = (vc, allRouteDetails) => {
  if (!Array.isArray(allRouteDetails)) {
    return;
  }
  const initialRouteKey = vc.$store.getters.initialRouteKey;
  if (!initialRouteKey) {
    return;
  }
  vc.$store.commit('initialRouteKey', ''); // Only runs once ever!
  const routeDetails = allRouteDetails.find(
    (routeDetails) =>
      routeDetails.shortName === initialRouteKey ||
      routeDetails.name === initialRouteKey,
  );
  if (routeDetails == null) {
    return;
  }
  vc.$store.commit('currentRouteShortName', routeDetails.shortName);
};

const _handleInitialStopKeyLoad = (vc, currentDirectionOptions) => {
  if (!_.isArray(currentDirectionOptions)) {
    return;
  }
  if (_.isEmpty(currentDirectionOptions)) {
    return;
  }
  const initialStopKey = vc.$store.getters.initialStopKey;
  if (!initialStopKey) {
    return;
  }
  vc.$store.commit('initialStopKey', ''); // Only runs once ever!
  const initialStopCode = parseInt(initialStopKey, 10);
  if (!_.isInteger(initialStopCode)) {
    return;
  }
  let hasValidStopCode = false;
  let directionId = undefined;
  let directionDisplayName = undefined;
  currentDirectionOptions.forEach((directionOption) => {
    if (hasValidStopCode) {
      return;
    }
    if (!_.isObjectLike(directionOption)) {
      return;
    }
    if (typeof directionOption.label !== 'string') {
      return;
    }
    if (!_.isArray(directionOption.stops)) {
      return;
    }
    if (_.isEmpty(directionOption.stops)) {
      return;
    }
    directionOption.stops.forEach((stop) => {
      if (_.get(stop, 'code') !== initialStopCode) {
        return;
      }
      hasValidStopCode = true;
      directionId = directionOption.id;
      directionDisplayName = directionOption.label;
    });
  });
  if (!hasValidStopCode) {
    return;
  }
  if (!directionDisplayName) {
    return;
  }
  setTimeout(() => {
    vc.$store.commit('currentDirectionId', directionId);
    setTimeout(() => {
      vc.$store.commit('currentStopCode', initialStopCode);
    }, 100);
  }, 100);
};

let _heartbeatsInterval = undefined;

const App = {};

App.components = {};
App.components['agency-index'] = AgencyIndex;
App.components['agency-predictions-container'] = AgencyPredictionsContainer;

// <lifecycle hooks>
App.created = function () {
  const vc = this;

  initializeTracking(vc.$store);

  _initializeRoute(vc);

  _initializeAgencyInfo(vc);

  let heartbeats = 0;
  _heartbeatsInterval = setInterval(() => {
    heartbeats++;
    vc.$store.commit('heartbeat', heartbeats);
  }, 1000);
};

App.beforeDestroy = function () {
  // const vc = this
  clearInterval(_heartbeatsInterval);
};
// </lifecycle hooks>

// <watchers>
App.watch = {};

App.watch['$store.getters.currentRouteShortName'] = function () {
  const vc = this;
  _updateRoute(vc);
};

App.watch['$store.getters.currentStopCode'] = function () {
  const vc = this;
  _updateRoute(vc);
};

App.watch['$store.getters.allRouteDetails'] = function (allRouteDetails) {
  const vc = this;
  _handleInitialRouteKeyLoad(vc, allRouteDetails);
};

App.watch['$store.getters.currentDirectionOptions'] = function (
  currentDirectionOptions,
) {
  const vc = this;
  _handleInitialStopKeyLoad(vc, currentDirectionOptions);
};
// </watchers>

export default App;
</script>

<style lang="stylus">
* {
    box-sizing border-box
}
body {
    margin 0
    padding 0
    font-family Roboto, 'Helvetica Neue', Arial, sans-serif
    font-weight 300
    background-color #f3f6f7
}
</style>
