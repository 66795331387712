<template>
  <div id="nav-footer" class="nav-footer-container">
    <template v-if="showAppEndorsement">
      <h4 class="footer-cta">Get the {{ appEndorsement.name }} mobile app</h4>
      <div class="badges">
        <a :href="appEndorsement.appleStoreUrl" target="_blank">
          <img
            class="store-badge apple"
            src="../images/app-store-badge.png"
            alt="apple store icon"
          />
        </a>
        <a :href="appEndorsement.googlePlayUrl" target="_blank">
          <img
            class="store-badge google"
            src="../images/google-play-badge.png"
            alt="google play store icon"
          />
        </a>
      </div>
    </template>
    <h4 class="footer-cta">Powered by</h4>
    <a href="https://www.goswift.ly" target="_blank">
      <img
        class="swiftly-logo"
        src="../images/swiftly-logo-with-name.png"
        alt="swiftly-logo"
      />
    </a>
    <LegalLinks />
  </div>
</template>

<script>
import LegalLinks from './LegalLinks.vue';
import { AGENCIES_TO_HIDE_ENDORSEMENT } from '../constants';

const TRANSIT_APP_DETAILS = {
  name: 'Transit',
  appleStoreUrl: 'https://itunes.apple.com/app/apple-store/id498151501',
  googlePlayUrl:
    'https://play.google.com/store/apps/details?id=com.thetransitapp.droid',
};

export default {
  name: 'nav-footer',
  components: { LegalLinks },
  data() {
    return {
      appEndorsement: TRANSIT_APP_DETAILS,
    };
  },
  computed: {
    agencyKey() {
      return this.$store.getters.agencyKey;
    },
    showAppEndorsement() {
      const shouldHide = AGENCIES_TO_HIDE_ENDORSEMENT.has(this.agencyKey);
      return !shouldHide;
    },
  },
};
</script>

<style lang="stylus" scoped>

.nav-footer-container {
    width 85%
    margin-left 7.5%
    text-align center
    margin-bottom 60px

    .footer-cta {
        margin-top 0
        padding-top 21px
        font-family Roboto, sans-serif
        font-weight 400
        color #5d5d5d
        font-weight 500
    }

    .badges {
        margin-bottom 20px
        padding-bottom 40px
        border-bottom 1px solid #DDD
    }

    .store-badge {
        height 45px
        &.google {
            margin-top 15px
        }
    }
    .swiftly-logo {
        max-width 120px
    }
}
</style>
