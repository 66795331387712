<template>
  <div id="nav-controls">
    <div
      class="nav-controls-container"
      :class="{ 'has-predictions': showPredictions }"
    >
      <div class="desktop-selects">
        <h2 class="view-selection">Route</h2>
        <el-select
          v-model="routesModel"
          placeholder="Select a route..."
          clearable
          filterable
        >
          <el-option
            v-for="item in $store.getters.currentRouteOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <h2 class="view-selection">Direction</h2>
        <el-select
          v-model="directionsModel"
          placeholder="Select a direction..."
        >
          <el-option
            v-for="item in $store.getters.currentDirectionOptions"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
        <h2 class="view-selection">Stop</h2>
        <el-select
          v-model="stopsModel"
          placeholder="Select a stop..."
          clearable
          filterable
        >
          <el-option
            v-for="item in $store.getters.currentStopOptions"
            :key="item.code"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <!-- end div.desktop-selects -->

      <div class="mobile-selects">
        <h2 class="view-selection">Route</h2>
        <select v-model="routesModel">
          <option value="" disabled selected>Select a route...</option>
          <option
            v-for="item in $store.getters.currentRouteOptions"
            v-text="item.value"
            :key="item.value"
            :value="item.value"
          ></option>
        </select>
        <h2 class="view-selection">Direction</h2>
        <select v-model="directionsModel">
          <option value="" disabled selected>Select a direction...</option>
          <option
            v-for="item in $store.getters.currentDirectionOptions"
            v-text="item.value"
            :key="item.id"
            :value="item.id"
          ></option>
        </select>
        <h2 class="view-selection">Stop</h2>
        <select v-model="stopsModel">
          <option value="" disabled selected>Select a stop...</option>
          <option
            v-for="item in $store.getters.currentStopOptions"
            v-text="item.label"
            :key="item.code"
            :value="item.value"
          ></option>
        </select>
      </div>
    </div>
    <!-- end div.mobile-selects -->

    <div
      v-if="showPredictions"
      class="arrival-prediction-container"
      :class="{ 'is-mobile': !isLargeScreen }"
    >
      <p v-if="!$store.getters.currentStopCode">
        Select a route, direction and stop to see real time predictions.
      </p>
      <template v-else>
        <div
          class="individual-prediction"
          v-for="item in arrivalTimesArray"
          :key="item.headsign"
        >
          <p v-text="item.headsign" />
          <h2 class="arrival-times-cta" v-text="item.timesString" />
        </div>
        <p v-text="lastUpdatedText" class="last-updated-text" />
        <template v-if="hasPrediction">
          <p v-if="showScheduleDisclaimer" class="last-updated-text">
            * Schedule-based
          </p>
          <p v-else class="last-updated-text">
            All predictions are real-time
            <i class="material-icons">rss_feed</i>
          </p>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Loading } from 'element-ui';
import _ from 'lodash';
import { ROUTE_SELECTED_EVENT, STOP_SELECTED_EVENT, track } from '../tracking';

// <private>
const ARRIVAL_TIMES_EMPTY_STATE_COPY = 'Select a stop to see arrival times.';
const SECOND_LENGTH_IN_MS = 1000;

let _fetchPredictionsInterval = null;
// <private>

const NavControls = {};

NavControls.name = 'nav-controls';

NavControls.props = {};
NavControls.props.isLargeScreen = { type: Boolean, required: true };

NavControls.data = () => ({
  tickerTime: 0,
});

NavControls.computed = {};

NavControls.computed.routesModel = {
  get: function () {
    const vc = this;
    return vc.$store.getters.currentRouteShortName;
  },
  set: function (newValue) {
    const vc = this;
    track(ROUTE_SELECTED_EVENT);
    vc.$store.commit('currentRouteShortName', newValue);
  },
};

NavControls.computed.directionsModel = {
  get: function () {
    const vc = this;
    return vc.$store.getters.currentDirectionId;
  },
  set: function (newValue) {
    const vc = this;
    vc.$store.commit('currentDirectionId', newValue);
  },
};

NavControls.computed.stopsModel = {
  get: function () {
    const vc = this;
    return vc.$store.getters.currentStopCode;
  },
  set: function (newValue) {
    const vc = this;
    track(STOP_SELECTED_EVENT, { source: 'filterPanel' });
    vc.$store.commit('currentStopCode', newValue);
  },
};

NavControls.computed.arrivalTimesArray = (vc) =>
  vc.$store.getters.arrivalTimesArray;

NavControls.computed.lastUpdatedText = (vc) =>
  vc.$store.getters.lastUpdatedText;

NavControls.computed.showPredictions = (vc) =>
  vc.$store.getters.currentStopCode || !vc.isLargeScreen;

NavControls.computed.showScheduleDisclaimer = (vc) => {
  const scheduledPrediction = _.find(
    vc.$store.getters.arrivalTimesArray,
    (item) => item.hasScheduleBased,
  );
  return scheduledPrediction ? true : false;
};

NavControls.computed.hasPrediction = (vc) => {
  const hasPrediction = _.find(
    vc.$store.getters.arrivalTimesArray,
    (item) => item.hasPrediction,
  );
  return hasPrediction ? true : false;
};

NavControls.methods = {};

NavControls.methods.clearRouteSelectionAssociatedState = function () {
  const vc = this;
  vc.$store.commit('currentStopCode', '');
  vc.$store.commit('currentDirectionId', '');
};

NavControls.methods.setPredictionData = function (predictionData) {
  const vc = this;

  if (!_.isObjectLike(predictionData)) {
    vc.$store.commit('currentPredictions', null);
    return;
  }

  const flattenedPredictionData = _.flatten(predictionData);
  const destinations = flattenedPredictionData[0]
    ? flattenedPredictionData[0].destinations
    : null;

  if (!_.isObjectLike(destinations)) {
    vc.$store.commit('currentPredictions', null);
    return;
  }

  vc.$store.commit('currentPredictions', destinations);
};

NavControls.methods.fetchAgencyRoutesInfo = async function () {
  const vc = this;

  const url = `${process.env.API_GATEWAY_URL}/info/${vc.$store.getters.agencyKey}/routes?verbose=true`;
  const headers = { Authorization: process.env.SWIFTLY_AUTH_KEY };
  const fetchConfig = {
    method: 'GET',
    url,
    headers,
    timeout: 0,
  };
  let resp = undefined;
  try {
    resp = await axios(fetchConfig);
  } catch (error) {
    console.log(
      `NavControls.methods.fetchAgencyRoutesInfo error: ${error.message}`,
    );
    alert('Unexpected error: please try reloading the page');
    if (!_.isObjectLike(vc.loading)) {
      return;
    }
    if (typeof vc.loading.close !== 'function') {
      return;
    }
    vc.loading.close();
    return;
  }
  const allRouteDetails = _.get(resp, ['data', 'data', 'routes']);
  if (allRouteDetails) {
    vc.$store.commit('allRouteDetails', allRouteDetails);
  }
  if (!_.isObjectLike(vc.loading)) {
    return;
  }
  if (typeof vc.loading.close !== 'function') {
    return;
  }
  vc.loading.close();
};

NavControls.methods.tick = function () {
  const vc = this;

  vc.tickerTime += 1000;

  if (typeof vc.$store.getters.currentStopCode !== 'number') {
    vc.$store.commit('lastUpdatedText', '');
    return;
  }

  const secondsElapsed = Math.floor(vc.tickerTime / SECOND_LENGTH_IN_MS);
  if (secondsElapsed <= 1) {
    vc.$store.commit('lastUpdatedText', 'Last updated just now.');
    return;
  }

  vc.$store.commit(
    'lastUpdatedText',
    `Last updated ${secondsElapsed} seconds ago.`,
  );
};

NavControls.methods.pollPredictionData = async function () {
  const vc = this;

  vc.$store.commit('isLoadingPredictions', true);

  const stopId = vc.$store.getters.currentStopCode;
  if (!stopId) {
    return;
  }

  const shortName = vc.$store.getters.currentRouteShortName;
  if (!shortName) {
    return;
  }
  const url =
    `//api.goswift.ly/real-time/${vc.$store.getters.agencyKey}/predictions` +
    `?route=${shortName}&stop=${stopId}`;
  const headers = { Authorization: process.env.SWIFTLY_AUTH_KEY };
  const fetchConfig = {
    method: 'GET',
    url,
    headers,
    timeout: 0,
  };
  let resp = undefined;
  try {
    resp = await axios(fetchConfig);
    vc.$store.commit('isLoadingPredictions', false);
  } catch (error) {
    console.log(
      `NavControls.methods.pollPredictionData error: ${error.message}`,
    );
    vc.$store.commit('isLoadingPredictions', false);
    return;
  }
  const predictions = _.get(resp, ['data', 'data', 'predictionsData']);
  if (!_.isArray(predictions)) {
    return;
  }
  vc.setPredictionData(predictions); // Lots of munging todo so this gets a method
  vc.tickerTime = 0;
};

// <lifecycle hooks>
NavControls.created = function () {
  const vc = this;
  vc.fetchAgencyRoutesInfo();
};

NavControls.mounted = function () {
  const vc = this;
  vc.loading = Loading.service({
    target: document.body,
    body: false,
    fullscreen: true,
    lock: false,
    text: 'Loading...',
  });
};

NavControls.beforeDestroy = function () {
  const vc = this;

  vc.tickerTime = 0;

  clearInterval(_fetchPredictionsInterval);

  vc.$nextTick(() => {
    // Loading should be closed asynchronously
    if (!_.isObjectLike(vc.loading)) {
      return;
    }
    if (typeof vc.loading.close !== 'function') {
      return;
    }
    vc.loading.close();
  });
};
// </lifecycle hooks>

// <watchers>
NavControls.watch = {};

NavControls.watch['$store.getters.currentRouteShortName'] = function (
  newShortName,
) {
  const vc = this;

  vc.$store.commit('currentStopCode', '')
  if (!newShortName) {
    vc.clearRouteSelectionAssociatedState();
    return;
  }

  // When a new route is selected, select the first available direction
  const { id } = vc.$store.getters.currentDirectionOptions[0] || {};
  if (id != null) {
    vc.$store.commit('currentDirectionId', id);
  }
};

NavControls.watch['$store.getters.currentDirectionId'] = function () {
  const vc = this;
  vc.$store.commit('currentStopCode', '');
};

NavControls.watch['$store.getters.currentStopCode'] = function (newValue) {
  const vc = this;

  clearInterval(_fetchPredictionsInterval);

  if (!newValue) {
    vc.$store.commit('currentPredictions', null);
    return;
  }

  vc.pollPredictionData();
  _fetchPredictionsInterval = setInterval(
    vc.pollPredictionData,
    1000 * 10, // 10 Seconds
  );
};

NavControls.watch['$store.getters.heartbeat'] = function () {
  const vc = this;
  vc.tick();
};

export default NavControls;
</script>
<style lang="stylus">
#nav-controls {
    .nav-controls-container {
        width 85%
        margin-left 7.5%
        margin-bottom 20px
        padding 0 1.5rem 36px
        border-bottom 1px solid #DDD

        &.has-predictions {
            border-bottom none
            padding-bottom 11px
            margin-bottom 0
        }

        .view-selection {
            font-size 14px
            font-size 0.9rem
            line-height 1.2em
            margin 30px 0 0 0
            font-weight 500
        }
        .el-select {
            width 100%
            margin 10px 0
        }
    }

    .arrival-prediction-container {
        text-align center
        background-color #eee
        color #000
        min-height 80px
        width 100%
        margin 15px 0
        padding 1rem 0

        p {
            margin 0px 10px
        }

        .individual-prediction {
           margin 0px 15px 20px
           padding-bottom 20px
           border-bottom 1px solid #777
        }

        .arrival-times-cta {
            font-size 20px
            margin 5px 0 0px 0
            font-weight 500
        }

        &.is-mobile {
            background-color #00a1df
            color #fff

            .individual-prediction {
                border-color #fff
            }
        }

    }
    .mobile-selects {
        display none
    }
    @media screen and (max-width: 812px) {
        .mobile-selects {
            display block
            select {
                margin 15px 0 0 0
                width 100%
                height: 40px
            }
        }
        .desktop-selects {
            display none
        }
    }
}
</style>
