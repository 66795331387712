<template>
  <div id="nav-agency-header" v-show="logoSrc" class="agency-header-container">
    <div
      class="top-logo"
      :style="{ backgroundImage: 'url(' + logoSrc + ')' }"
      :alt="agencyName + ' Logo'"
    />
    <h4 class="agency-name" v-text="agencyName"></h4>
  </div>
</template>

<script>
const NavAgencyHeader = {};

NavAgencyHeader.name = 'nav-agency-header';

NavAgencyHeader.data = () => ({});

NavAgencyHeader.computed = {};

NavAgencyHeader.computed.agencyName = (vc) => {
  return vc.$store.getters.agencyName;
};

NavAgencyHeader.computed.logoSrc = (vc) => {
  if (vc.$store.getters.hideLogoSection) {
    return null;
  }
  const agencyKey = vc.$store.getters.agencyKey;
  if (typeof agencyKey === 'string' && agencyKey.length > 0) {
    return require(`../images/agency-icons/${agencyKey}.png`);
  }
  return null;
};

export default NavAgencyHeader;
</script>

<style lang="stylus" scoped>
.agency-header-container {
    text-align center
    margin-top 20px
    margin-bottom 20px
    width 85%
    margin-left 7.5%
    border-bottom 1px solid #ddd

    .top-logo {
        width 80%
        height 80px
        margin 0 auto
        background-size contain
        background-repeat no-repeat
        background-position center
    }

    h4 {
        margin-top 10px
        margin-bottom 25px
        font-size 18px
        font-size 1.2rem
    }
}
</style>
