const state = {};

state.agencyBounds = null;
state.agencyKey = '';
state.agencyName = '';
state.allRouteDetails = null;
state.currentRouteShortName = '';
state.currentPredictions = null;
state.currentDirectionId = '';
state.currentStopCode = '';
state.currentVehicleId = '';
state.heartbeat = 0;
state.initialRouteKey = '';
state.initialStopKey = '';
state.isLoadingPredictions = false;
state.isTrackingEnabled = window.isTrackingEnabled;
state.lastUpdatedText = '';
state.selectedStopCode = '';
state.vehicles = JSON.stringify([]);

export default state;
